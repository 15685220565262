import React, { useState, useMemo } from 'react';
import queryString from 'query-string';
import { graphql, navigate } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { gql, useQuery } from 'urql';
import Card from '@mui/material/Card';

// custom components
import { SearchProvider } from '../../context/SearchContext';
import { useSettings } from '../../hooks/SettingsHook';
import { Layout } from '../../components/layout/PageSingle';
import StyledBox from '../../components/controls/StyledBox';
import Booking from '../../custom/segments/Booking';
import Gallery from '../../custom/segments/Gallery';
import ProductsRelated from '../../custom/segments/ProductsRelated';
import Newsletter from '../../custom/segments/NewsletterSimple';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

// static build query
export const query = graphql`
  query ($id: String) {
    page: datoCmsPage(id: { eq: $id }) {
      pagetype
      title
      coverHeader
      teaser
      content
      footer
      coverImage {
        gatsbyImageData(width: 1600)
        alt
      }
      gallery {
        alt
        basename
        fixed(width: 250) {
          src
        }
        gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.3)
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

// dynamic client query
const ProductQuery = gql`
  query ($id: ItemId = "") {
    item(filter: { id: { eq: $id } }) {
      title
      alias
      booking
      featured
      related {
        id
        producttype
        title
        alias
        coverHeader
        booking
        featured
        weight
        teaser
        price
        ismin
        priceAlias
        image {
          responsiveImage(imgixParams: { fit: crop, w: 450, h: 300, auto: format }) {
            ...responsiveImageFragment
          }
          basename
        }
      }
    }
  }
  fragment responsiveImageFragment on ResponsiveImage {
    title
    alt
    aspectRatio
    width
    height
    sizes
    src
    srcSet
    webpSrcSet
    base64
  }
`;

function BookingOkPage({ data, location, pageContext }) {
  // context
  const { settings } = useSettings() || {};

  // parameter management
  const { page, site } = data;
  const { catalogType, catalog } = pageContext;

  const searchParams = location.search;
  const isClient = typeof searchParams === 'string';
  const queryParams = isClient ? queryString.parse(searchParams) : searchParams;
  const productKey = queryParams && queryParams.key ? queryParams.key : '';
  const productSelected = useMemo(() => productKey, []);

  // state

  // fetching
  const shouldPause = !productSelected;
  const [result] = useQuery({
    query: ProductQuery,
    variables: { id: productSelected },
    pause: shouldPause,
  });
  const { data: clientData, fetching, error } = result;
  const product = clientData ? clientData.item : {};
  const related = product.related;

  // console.log('=== BOOKING OK ===');
  // console.log(`productSelected: ${productSelected}`);
  // console.log(product);

  // event handler
  const handleBackClick = () => {
    const navlink = settings && settings.caller ? settings.caller.booking : '';
    navigate(navlink);
  };

  // data management
  const pageHeader = nodesUtils.getLabelFromPageType(page.pagetype);
  const coverHeader = page && page.coverHeader ? page.coverHeader : page.title;

  const showNewsletter = config.segments.newsletter.enabled;

  const galleryImages = nodesUtils.getGalleryImages(page.gallery);
  const pageParams = {
    title: coverHeader,
    cover: { ...page.coverImage },
  };

  const productParams = settings && settings.product ? { ...settings.product } : {};
  const userParams = {
    firstName: '',
    lastName: '',
    code: '',
    department: '',
  };

  return (
    <Layout catalogType={catalogType} catalogId={catalog} pageParams={pageParams}>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: -8,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StyledBox py={{ xs: 1, md: 2 }}>
          <Booking
            header={pageHeader}
            title={page.title}
            teaser={page.teaser}
            body={page.content}
            footer={page.footer}
            user={userParams}
            product={productParams}
            showForm={false}
            onBackClick={handleBackClick}
          />
        </StyledBox>
        <Gallery header={'Bildergalerie'} images={galleryImages} />
        <ProductsRelated
          products={related}
          header={'Das könnte ebenfalls interessant sein'}
          catalogType={catalogType}
          bookingPage={true}
        />
        {showNewsletter && <Newsletter />}
      </Card>
    </Layout>
  );
}

// page component
export default function BookingOkPageTemplate(props) {
  const { data } = props;
  const { page, site } = data;

  return (
    <SearchProvider>
      <HelmetDatoCms seo={page.seo} favicon={site.favicon} />
      <BookingOkPage {...props} />
    </SearchProvider>
  );
}
